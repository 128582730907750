import { InMemoryCache } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import * as R from "ramda";

import useFreeze from "../../components/utilities/useFreeze";
import possibleTypes from "./possibleTypes";

const createInMemoryCache = () =>
	new InMemoryCache({
		possibleTypes,
		typePolicies: {
			Account: {
				keyFields: [],
			},
			AssociatedAccount: {
				keyFields: ["id"],
			},
			Holding: {
				keyFields: ["id"],
				fields: {
					searchTransactions: relayStylePagination(),
				},
			},
			InvestingEntity: {
				keyFields: ["id"],
				fields: {
					searchDocuments: relayStylePagination(),
					searchTransactions: relayStylePagination(),
				},
			},
			Session: {
				keyFields: [],
			},
			OfferInvestor: {
				keyFields: false,
			},
			Portfolio: {
				keyFields: [],
				fields: {
					hasInvested: {
						read: (_, { readField }) => {
							const orders = readField("portfolioOrders");
							const distributions = readField("portfolioDistributions");
							const holdings = readField("holdings");

							const hasItems = R.complement(R.either(R.isEmpty, R.isNil));

							// A user has invested if they have any orders, holdings, or distributions
							return R.any(hasItems)([orders, holdings, distributions]);
						},
					},
					holdingById: {
						read: (_, { args, toReference }) =>
							toReference({
								__typename: "Holding",
								id: args.id,
							}),
					},
					wallet: {
						read: (_, { args, toReference }) =>
							toReference({
								__typename: "Wallet",
								currency: {
									// Wallet is queried by `currencyAbbreviation`
									abbreviation: args.currencyAbbreviation?.toUpperCase(),
								},
							}),
					},
				},
			},
			Query: {
				fields: {
					searchDocuments: relayStylePagination(),
					searchTransactions: relayStylePagination(),
				},
			},
			Fund: {
				keyFields: false,
			},
			Wallet: {
				// Wallet is cached by the abbreviation of its currency
				keyFields: ({ currency }) => currency.abbreviation?.toUpperCase(),
				fields: {
					searchTransactions: relayStylePagination(),
				},
			},
		},
	});

const useInMemoryCache = () => {
	const cache = useFreeze(createInMemoryCache);

	return cache;
};

export default useInMemoryCache;
